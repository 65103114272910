import React, { Component } from "react"
import Header from "./components/header"
import Footer from "./components/footer"

class Terms extends Component {
  state = {}
  render() {
    return (
      <>
        <Header />
        <div className="terms-cover-container-v1">
          <div className="terms-container-v1">
            <p>
              Thanks for using our platform and trusting us with your career
              goals. This website and platform ("Services") are provided by
              Plancv Ltd. ("us", "we", or "our").
            </p>
            <br />
            <p>
              By using Plancv or accessing any of our Services, you are agreeing
              to the following terms. Please read them carefully and contact us
              if you have any questions.
            </p>
            <br />
            <p>
              When you create an account on Plancv, you are fully responsible
              for maintaining its security and any activities that occur under
              your account. Please, use a "strong" password (or authenticate via
              a third-party) to ensure that your private information, your
              resumes and billing information are safe.
            </p>
            <br />
            <p>
              We cannot and will not be liable for any loss or damage arising
              from your failure to comply with the above requirements.
            </p>
            <br />
            <p>
              You are solely responsible for all the content you create using
              our Services. We may review content to determine whether it
              violates our policies, and we may remove content in those cases.
              But that does not necessarily mean that we review content, so
              please don’t assume that we do.
            </p>
            <br />
            <p>
              Plancv reserves the right to require payment or subscription fees
              for any of our Services. At the beginning of each billing period
              you will be automatically charged for the entire period.
            </p>
            <br />
            <p>
              The prices may be changed at any time, upon thirty (30) day prior
              notice to you, which may be sent by email or posted on the
              website. Your use of the Services following such notification
              constitutes your acceptance of any new or increased charges.
            </p>
            <br />
            <p>
              Downgrading your plan may cause the loss of access to some
              capabilities of your Account. Plancv does not accept any liability
              for such loss.
            </p>
            <br />
            <p>
              Please know that all prices on the Plancv website and all charges
              made are in US Dollars.
            </p>
            <br />
            <p>
              If you no longer need our Service, for example after you have
              found an amazing new job, it is solely your responsibility to
              cancel the subscription (through the Billing page) before the next
              billing date. After successful cancellation of your subscription
              you will receive an email confirmation.
            </p>
            <br />
            <p>
              We are not always able to respond to cancellation requests via
              email, nor to issue a refund if have not canceled (unless there is
              evidence of a technical issue on our side that prevented you from
              canceling).
            </p>
            <br />
            <p>
              Any monthly fees paid hereunder are non-refundable if you have
              used our services during that billing period (unless there is
              evidence of a technical issue on our side that prevented you from
              canceling or using our services entirely). Quarterly and
              Semi-annual fees are eligible for a partial pro-rated refund.
            </p>
            <br />
            <p>
              Any trial or reduced pricing (promocode) must be used within the
              specified time. You must cancel your account before the end of the
              promotional period to avoid being charged a regular fee for next
              billing period (always refer to information in Billing page). We
              reserve the right to limit you to one trial period or a single
              promocode as well as to prohibit the combination of trials,
              promocodes, and other offers (such as inviting friends). Credit
              accumulated through inviting friends cannot be exchanged,
              refunded, replaced or redeemed for cash. All promocode-reduced
              fees are non-refundable.
            </p>
            <br />
            <p>
              Plancv, in its sole discretion, has the right to terminate your
              account for any reason at any time. Such termination of the
              Services will result in the deactivation or deletion of your
              account and the forfeiture and relinquishment of all content in
              your account. In such cases, you are not eligible for any refunds.
            </p>
            <br />
            <p>
              If you wish to terminate your account, you may do so through the
              Account page.
            </p>
            <br />
            <p>
              Your personal information and content is governed by our Privacy
              Policy. By using Plancv, you agree to our Privacy Policy.
            </p>
            <br />
            <p>
              Plancv does not make any warranty about the reliability of the
              Services and does not guarantee the security of user data despite
              best efforts. The Service is provided “AS IS” and you agree to not
              hold us responsible nor to seek indemnification for any damages
              that may arise as a result of the loss of use, data, or profits
              connected to the performance of the Services or failure in such
              performance.
            </p>
            <br />
            <p>
              We reserve the right to modify these terms at any time. If a
              revision is material, we will try to provide at least 15 days
              notice prior to any new terms taking effect. What constitutes a
              material change will be determined at our sole discretion. By
              continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, please stop using the Service.
            </p>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Terms
